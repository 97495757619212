import React from 'react';
import { Button } from 'reactstrap';
import axios from 'axios';
//import withRedux from 'next-redux-wrapper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeadHtml from '../components/HeadHtml';
import PageLoader from '../components/PageLoader';
import PA2Login from '../components/PA2Login';
import { getCookie } from '../utils/CookieUtils';
//import { initStore } from '../store';
import { data } from '../config';

class Login extends React.Component {
  //static async getInitialProps() {
  //  const res = await fetch('https://randomuser.me/api/?results=3');
  //  const dati = await res.json();
  //  return { stars: dati.results };
  //}
  static getInitialProps(context) {
    const { m } = context.query;
    //console.log(`Fetched m: ${m}`);
    return { tessera: m };
  }

  state = {
    pageLoading: true
  };

  componentWillMount() {
    this.setState({ pageLoading: true });
  }

  componentDidMount() {
    this.setState({ pageLoading: false });
    //setTimeout(() => this.setState({ pageLoading: false }), 5000); // caricamento simulato
    if (this.props.tessera) {
      console.log('Fetched m', this.props.tessera);
    }
    const cookie = getCookie('sessionId');
    if (cookie) {
      this.getUser();
    }
  }

  async getUser() {
    const res = await axios.get(
      `${data.serverUrl}/api/users/sessionid/${getCookie('sessionId')}`
    );
    console.log('res', res);
    const jsonUser = res.data;
    this.setState({
      userData: jsonUser
    });
  }

  render() {
    //console.log('login page props', this.props);
    if (this.state.pageLoading) {
      return <PageLoader />;
    }
    return (
      <HeadHtml>
        <div
          style={{
            backgroundImage: `url(${'../static/img/interior-1026456_1920_cr.jpg'})`,
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundSize: 'cover',
            opacity: 0.9,
            backgroundPosition: 'center center'
          }}
        >
          <PA2Login tessera={this.props.tessera || ''} />
        </div>
      </HeadHtml>
    );
  }
}

//export default withRedux(initStore, (state) => ({ tok: state.tk, test: state.new, prod: state.prod, premi: state.premi, stateRedux: state }))(Login);
const mapStateToProps = state => ({
    tkLogin: state.tk,
    redux: state
  });

const mapDispatchToProps = dispatch => ({
    //addTKprop: bindActionCreators(addTK, dispatch)
  });
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
