import React from 'react';
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from 'reactstrap';
import Link from 'next/link';
import axios from 'axios';
import Router from 'next/router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCookie, getCookie } from '../utils/CookieUtils';
import { addTK } from '../actions';
import { data } from '../config';

/* global sessionStorage window */

class PA2Login extends React.Component {
  state = {
    usr: '',
    pwd: '',
    token: '',
    errorMessage: '',
    infoMessageModal: '',
    modalRichiestaPass: false,
    codiceAgenteFischer: ''
  };

  componentDidUpdate() {
    console.log('PA2Login update', this.state);
  }

  componentDidMount() {
    console.log('PA2Login DidMount state', this.state);
    console.log('login props', this.props);
    if (this.props.tessera) {
      setCookie('x-access-token', '');
      setCookie('sessionId', '');
      this.login(this.props.tessera);
    }
    sessionStorage.clear();
  }

  toggleModalRichiestaPass = () => {
    this.setState({
      modalRichiestaPass: !this.state.modalRichiestaPass,
    });
    if (this.state.modalRichiestaPass === false) {
      this.setState({
        infoMessageModal: '',
      });
    }
  }

  changeUsername = e => {
    this.setState({
      usr: e.target.value
    });
  };
  changePassword = e => {
    this.setState({
      pwd: e.target.value
    });
  };
  changeCodiceAgenteFischer = e => {
    this.setState({
      codiceAgenteFischer: e.target.value
    });
  };

  login = async (tessera, event) => {
    // console.log('event', event);
    if (event) {
      event.preventDefault();
    }
    try {
      //this.props.addTKprop(this.state.usr);
      const check = await axios.post(`${data.serverUrl}/api/users/check`, {
        username: this.state.usr,
        password: this.state.pwd,
        pkPiat: data.pkPiat,
        tessera
      });
      console.log('check', check);
      console.log('check true', check.data.oneuser);
      //let auth = check.data.success;
      // const res = await axios.post('http://pac.publitalia.srl/api/auth', this.state);
      let res = !!check.data.oneuser;
      console.log('start res before IF', res);

      if (res) {
        res = await axios.post(`${data.serverUrl}/api/auth`, {
          userId: check.data.oneuser._id
          // username: this.state.usr,
          // password: this.state.pwd,
        });
      }
      const oneuserId = !!check.data.oneuser;
      const dataSuccess = !!res.data;
      console.log('oneuserId', oneuserId);
      console.log('dataSuccess', dataSuccess);
      if (oneuserId && dataSuccess) {
        //this.props.addTKprop(res.data.token);
        const setTk = await axios.put(`${data.serverUrl}/api/users/uptk`, {
          username: this.state.usr,
          password: this.state.pwd,
          tessera,
          tk: res.data.token
        });
        setCookie('x-access-token', res.data.token, 12); // 12 ore
        setCookie('sessionId', check.data.oneuser._id, 12); // 12 ore
        //console.log('setTk.statusText', setTk.statusText);
        //sessionStorage.setItem('tk', res.data.token);
        // if (setTk.statusText === 'OK') {
        if (setTk.data.checkStatus === 'OK') {
          Router.push({
            pathname: '/'
          });
        }
      } else {
        this.setState({
          errorMessage: 'Login Fallito'
        });
      }
    } catch (error) {
      this.setState({
        errorMessage: error
      });
    }
  };

  richiestaSendMail = async (event) => {
    if (event) {
      event.preventDefault();
    }
    try {
      this.setState({
        infoMessageModal: 'Invio in corso'
      });
      const request = await axios.post('/login/richiestapass/', {
        codiceAgenteFischer: this.state.codiceAgenteFischer
      });
      setTimeout(() => {
        if (request.status === 200) {
          this.setState({
            infoMessageModal: 'Mail mandata con successo'
          });
        } else {
          this.setState({
            infoMessageModal: 'ERRORE: Mail non è stata mandata riprovare più tardi'
          });
        }
      }, 500);
    } catch (e) {
      console.error('error: ', e);
      this.setState({
        infoMessageModal: 'ERRORE: Mail non è stata mandata riprovare più tardi'
      });
    }
  }

  // getToken = async() => {
  // 	const res = await axios.post('http://pac.publitalia.srl/api/auth', this.state);
  // 	if (res.data.success) {
  // 		console.log('res.data', res.data);
  // 		setCookie('x-access-token', res.data.token);
  // 		window.sessionStorage.setItem('tk', res.data.token);
  // 		// Router.push({
  // 		//   pathname: '/'
  // 		// });
  // 	}
  // }

  render() {
    console.log('cookie app', getCookie('sessionId'));
    console.log('PA2Login props', this.props);

    return (
      <div>
        <Container style={{ paddingTop: 20, margin: '0 auto' }}>
          <Row className="h-100">
            <Col
              lg={{ size: 5 }}
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                borderRadius: ' 10px 10px 10px 10px'
              }}
              className="my-auto"
            >
              <Form
                style={{ maxWidth: 600, padding: 20 }}
                onSubmit={e => this.login('', e)}
              >
                <img
                  src="../static/img/fischer_logo_M_pos_4c.png"
                  alt="logo"
                  className="img-fluid"
                  style={{
                    borderRadius: '5px 5px 5px 5px',
                    display: 'block',
                    margin: '0 auto',
                    padding: '10px',
                    backgroundColor: '#fff'
                  }}
                />
                <FormGroup style={{ paddingTop: 10 }}>
                  <Label for="userName" sm={2} style={{ color: 'white' }}>
                    User
                  </Label>
                  <Col>
                    <Input
                      type="text"
                      name="username"
                      id="userName"
                      placeholder="inserire user"
                      onChange={this.changeUsername}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup style={{ paddingTop: 10 }}>
                  <Label for="userPassword" sm={2} style={{ color: 'white' }}>
                    Password
                  </Label>
                  <Col>
                    <Input
                      type="password"
                      name="password"
                      id="userPassword"
                      placeholder="inserire password"
                      onChange={this.changePassword}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup style={{ textAlign: 'center' }}>
                  {/* <Button onClick={() => this.login('')} color="primary" size="lg">Login</Button> */}
                  {/* <Button color="primary" size="lg" onEnter={(e) => this.login('', e)}>Login</Button> */}
                  <Button
                    color="primary"
                    className="buttonColor"
                    size="lg"
                    type="submit"
                  >
                    Login
                  </Button>
                  <hr />
                  <Button
                    color="secondary"
                    className="buttonColor"
                    size="lg"
                    onClick={this.toggleModalRichiestaPass}
                  >
                    Richiedi password
                  </Button>
                  <p style={{ color: 'red' }}>{this.state.errorMessage}</p>
                  {/* <p
                    style={{
                    color: '#ff2222',
                    fontWeight: 'bold',
                    backgroundColor: 'white',
                    textTransform: 'uppercase',
                    padding: '10px',
                    textAlign: 'center'
                    }}
                  >
                    Materiale causa esaurimento scorte dovute a Pandemia Mondiale Potrebbero Non essere Disponibili e Avere tempi di Consegna Lunghi
                  </p> */}
                </FormGroup>
              </Form>
            </Col>
            <Col
              lg={{ size: 5, offset: 1 }}
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                borderRadius: ' 10px 10px 10px 10px',
                padding: 30
              }}
              className="my-auto"
            >
              <img
                src="../static/img/pattuizioni-commerciali.png"
                alt="logo"
                className="img-fluid"
                style={{
                  borderRadius: '5px 5px 5px 5px',
                  display: 'block',
                  margin: '0 auto',
                  padding: '7px',
                  backgroundColor: '#fff'
                }}
              />
              {/* <h1 style={{ color: 'white' }}>Benvenuto!</h1> */}
              <h3 style={{ color: 'white', textAlign: 'center' }}>Benvenuto nel portale riservato alla forza vendita Fischer</h3>
              <hr style={{ borderColor: 'white' }} />
              <p style={{ color: 'white' }}>
              - Ogni ordine dovrà contenere solamente i prodotti oggetti di pattuizione, e di conseguenza rappresenterà <span style={{ color: '#e61f2b', fontWeight: 'bold' }}>uno ed un unico accordo</span>;<br />
              <br />
              - Sarà cura di Fischer Italia S.r.l. Unipersonale avvisarvi in merito all'esaurimento o sostituzione degli omaggi scelti;
              </p>
            </Col>
          </Row>
          {/* <p>username: {this.state.usr}</p>
					<p>password: {this.state.pwd}</p> */}
        </Container>
        {/* <div style={{ bottom: '10px', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.7)', width: '100%', textAlign: 'center', color: '#fff', paddingTop: '5px' }}> */}
        <div className="loginFoo">
          <p>
            Fischer Italia S.r.l.
            {/* Publitalia s.r.l. - Partita IVA: 00848720280 R.E.A. 153073 - CCIAA
            PD 00848720280 - publitaliasrl@pec.it - Sviluppato da Divisione
            Tecnica{' '}
            <a
              href="https://publitaliasrl.com/"
              className="cursorePointer"
              style={{ color: '#f5222d' }}
            >
              publitaliasrl.com
            </a> */}
          </p>
        </div>
        <Modal
          isOpen={this.state.modalRichiestaPass}
          toggle={this.toggleModalRichiestaPass}
        >
          <ModalHeader toggle={this.toggleModalRichiestaPass}>Richiedi password</ModalHeader>
          <Form
            style={{ maxWidth: 600, padding: 20 }}
            onSubmit={event => this.richiestaSendMail(event)}
          >
            <ModalBody style={{ textAlign: 'center' }}>
              Inserisci il tuo codice agente
              <FormGroup style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
                <Input name="codiceAgenteFischer" placeholder="codice agente" required onChange={this.changeCodiceAgenteFischer} />
              </FormGroup>
              <span>{this.state.infoMessageModal}</span>
            </ModalBody>
            <ModalFooter>
              <Button color={this.state.infoMessageModal !== '' ? 'secondary' : 'primary'} type="submit" className="cursorePointer" disabled={this.state.infoMessageModal !== ''}>
                Invia
              </Button>
              <Button color="danger" className="cursorePointer" onClick={this.toggleModalRichiestaPass}>
                Chiudi
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    tkLogin: state.tk,
    redux: state
  });

const mapDispatchToProps = dispatch => ({
    addTKprop: bindActionCreators(addTK, dispatch)
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PA2Login);
